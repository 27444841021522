// routes
import { PATH_CITY_GUIDES, PATH_TRIPS, PATH_EXPLORE, PATH_CONNECT } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

import { ReactComponent as City } from '../../../images/menu/city_frontend.svg';
import { ReactComponent as Trip } from '../../../images/menu/airplane_frontend.svg';
import { ReactComponent as Explore } from '../../../images/menu/house_frontend.svg';
import { ReactComponent as Connet } from '../../../images/menu/tab-meet-filled.svg'

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`../../../images/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),

  explore: <Explore />,
  city: <City />,
  trip: <Trip />,
  connet: <Connet />,
};

const navConfig = [
  // web
  // ----------------------------------------------------------------------
  {
    subheader: 'general v4.1.0',
    items: [
      { title: 'EXPLORE', path: PATH_EXPLORE.view, icon: ICONS.explore },
      { title: 'CITY GUIDES', path: PATH_CITY_GUIDES.view, icon: ICONS.city },
      { title: 'TRIPS', path: PATH_TRIPS.view, icon: ICONS.trip },
    ],
  },

];

const authenticatedNavConfig = [
  // web
  // ----------------------------------------------------------------------
  {
    subheader: 'general v4.1.0',
    items: [
      { title: 'EXPLORE', path: PATH_EXPLORE.view, icon: ICONS.explore },
      { title: 'CITY GUIDES', path: PATH_CITY_GUIDES.view, icon: ICONS.city },
      { title: 'TRIPS', path: PATH_TRIPS.view, icon: ICONS.trip },
      { title: 'TRAVELLERS', path: PATH_CONNECT.view, icon: ICONS.connet }
    ],
  },

];

const navVerticalConfig = [
  // Mobile 
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'EXPLORE', path: PATH_EXPLORE.view, icon: ICONS.explore },
      { title: 'CITY GUIDES', path: PATH_CITY_GUIDES.view, icon: ICONS.city },
      { title: 'CITY MAP', path: PATH_CITY_GUIDES.map, icon: ICONS.city },
      { title: 'TRIPS', path: PATH_TRIPS.view, icon: ICONS.trip },
      { title: 'TRAVELLERS', path: PATH_CONNECT.view, icon: ICONS.connet }
    ],
  },


  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.user.four },
  //         { title: 'Five', path: PATH_DASHBOARD.user.five },
  //         { title: 'Six', path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },


];


export { navConfig, navVerticalConfig, authenticatedNavConfig };
