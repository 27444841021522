import { useEffect, useState } from 'react';
import { Container, Typography, Grid, Chip, Tooltip, IconButton, Box, ImageList, ImageListItem, Skeleton, Link } from '@mui/material';
import Iconify from '../../components/iconify/Iconify';
import { GoogleMap, Marker } from "@react-google-maps/api"
import SimpleImageSlider from "react-simple-image-slider";

import _get from 'lodash/get'
import axios from '../../utils/axios';
import _groupBy from 'lodash/groupBy'
// components
import { useSettingsContext } from '../../components/settings';

import { ReactComponent as Instagram } from '../../images/icons/instagram_frontend.svg';
import { ReactComponent as Mail } from '../../images/icons/email_frontend.svg';
import { ReactComponent as Phone } from '../../images/icons/phone_frontend.svg';
import UserLocation from '../../images/map_badge_profile_frontend.svg';

import Scrollbar from '../../components/Scroll';
import { S3_BUCKET_KEY } from '../../config-global'

import './style/profile.css'

// ----------------------------------------------------------------------

const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

type Props = {
    handleClosePopUp?: () => void;
}

export default function ProfileView({ handleClosePopUp }: Props) {
    const [isLoading, setLoading] = useState(true);
    const { themeStretch } = useSettingsContext();
    const [profile, setProfile] = useState<any>([]);
    const [instaImages, setInstaImages] = useState<any>([]);
    const [instaImgloading, setInstaImgloading] = useState(false);

    useEffect(() => {

        const fetchUserProfile = async () => {
            try {
                setLoading(true)
                const { data } = await axios.get(`api/frontend/user/profile`)
                setProfile(data.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error('ERROR WHILE FETCHING USER PROFILE')
            }
        }
        fetchUserProfile()
    }, [])

    useEffect(() => {
        if (profile.longlive_access_token) getInstaImages();
    }, [profile])

    const getInstaImages = async () => {
        try {
            const fields = "media_url,permalink,media_type,thumbnail_url,username";
            const limit = 6;
            setInstaImgloading(true);
            const { data } = await axios.get(`https://graph.instagram.com/${profile.insta_user_id}/media?access_token=${profile.longlive_access_token}&fields=${fields}&limit=${limit}`)
            setInstaImgloading(false);

            if (data?.data) setInstaImages(data?.data);
        } catch (error) {
            setInstaImgloading(false);
            console.error(error)
        }
    }

    const getImageList = (imageList: any) => {
        const images: { url: string; }[] = []
        imageList && imageList.map((i: any) => images.push({ url: i.image_name }))
        return images
    }

    const images = getImageList(profile?.images).length ? getImageList(profile?.images) : [{
        url: `${S3_BUCKET_KEY}profile/profile_placeholder.jpg`
    }];

    const center = { lat: Number(profile?.location?.lat), lng: Number(profile?.location?.long) }

    const chipBoxStyle = {
        margin: 0, padding: 10, borderRadius: 10, border: 'thin solid rgba(145, 158, 171, 0.24)', fontSize: '0.75rem'
    }
    const pointer = { cursor: 'pointer' }

    // if (isLoading) return <div style={{ textAlign: 'center' }}>Loading...</div>

    return (
        <Scrollbar sx={isMobile ? { height: '90%' } : { height: '90vh' }}>
            <>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <Grid container style={{ paddingBottom: '2rem' }}>
                        {
                            handleClosePopUp &&
                            <Grid item md={12} >
                                <Box sx={{ position: 'relative', textAlign: 'right', paddingTop: '.5rem' }}>
                                    <Tooltip title="Close">
                                        <IconButton onClick={() => handleClosePopUp()}>
                                            <Iconify icon="ic:round-close" />
                                        </IconButton>
                                    </Tooltip>

                                </Box>
                            </Grid>
                        }

                        <Grid item md={7}>
                            <Grid container>
                                {!!images?.length &&
                                    <Grid item xs={12} sm={6} md={6} lg={6} >
                                        <SimpleImageSlider slideDuration={0.5} autoPlay={true} autoPlayDelay={10}
                                            width={window.innerWidth <= 900 ? '90%' : '55%'}
                                            height={'50vh'}
                                            images={images}
                                            showBullets={false}
                                            showNavs={images.length > 1}
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography variant='h6' style={{ paddingTop: '1.875rem' }}>{profile?.first_name}{`, `}{profile?.age}</Typography>
                                </Grid>

                                {
                                    profile?.tags_summary?.length &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '.5rem' }}>
                                        <fieldset style={chipBoxStyle}>
                                            <legend>Tags</legend>

                                            {
                                                //@ts-ignore
                                                profile?.tags_summary.map((tag: any) => <span ><Chip label={tag?.value} style={{ marginBottom: 5 }} />  </span>)
                                            }

                                        </fieldset>
                                    </Grid>
                                }

                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '.5rem' }}>
                                    <Grid container>
                                        <Grid item xs={4} sm={4} md={4} style={pointer}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ pr: 2, pt: 1 }}>
                                                    <Phone />
                                                </Box>
                                                <Typography variant='body2' className='info' style={{
                                                    lineHeight: 2.5,
                                                    // maxWidth: '270px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'clip'
                                                }}>
                                                    <a href={`tel:${profile?.phone}`} style={{ textDecoration: 'none', color: 'white' }}>
                                                        {profile?.phone || '-'} </a>
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={4} sm={4} md={4} style={pointer}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ pr: 2, pt: 1 }}>
                                                    <Mail />
                                                </Box>
                                                <Typography variant='body2' className='info' style={{
                                                    lineHeight: 2.5,
                                                    // maxWidth: '270px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'clip'
                                                }} >
                                                    <a href={`mailto:${profile?.email}`} style={{ textDecoration: 'none', color: 'white' }}>
                                                        {profile?.email || '-'} </a>
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={4} sm={4} md={4} style={pointer}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ pr: 2, pt: 1 }}  >
                                                    <Instagram />
                                                </Box>
                                                <Typography variant='body2' className='info' style={{
                                                    lineHeight: 2.5,
                                                    // maxWidth: '270px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'clip'
                                                }}
                                                    onClick={() => window.open(profile?.instagram, '_blank')}>
                                                    {profile?.instagram || '-'}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '.5rem' }}>
                                    <Typography variant='body2'> {profile?.about}</Typography>
                                </Grid>
                                {profile?.is_instagram_connected && instaImages.length !== 0 &&
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography
                                            variant='h6'
                                            style={{ paddingTop: '1rem' }}
                                        >
                                            <Link
                                                underline="none"
                                                target="_blank"
                                                href={`https://www.instagram.com/${profile?.insta_username}`}
                                            >
                                                {profile?.insta_username}
                                            </Link>
                                        </Typography>
                                        <ImageList
                                            cols={3}
                                            gap={25}
                                            sx={{ "cursor": "pointer", }}
                                            onClick={() => window.open(`https://www.instagram.com/${profile?.insta_username}`, '_blank')}
                                        >
                                            {instaImages.map((item: any) => (
                                                <ImageListItem key={item.id}>
                                                    <img src={item.media_url} loading="lazy" style={{ borderRadius: '20px' }} />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Grid>
                                }
                                {profile?.is_instagram_connected && instaImages.length === 0 && instaImgloading &&
                                    <Grid container spacing={4} item xs={12} sm={12} md={12} lg={12}>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Skeleton variant="rounded" height="11vw" />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Skeleton variant="rounded" height="11vw" />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Skeleton variant="rounded" height="11vw" />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Skeleton variant="rounded" height="11vw" />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Skeleton variant="rounded" height="11vw" />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Skeleton variant="rounded" height="11vw" />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {/* *********************** */}

                        <Grid item xs={12} sm={12} md={5} lg={5} style={{ paddingLeft: '20px' }}>
                            <GoogleMap
                                center={center}
                                zoom={11}
                                options={{
                                    mapId: "fbe9d1226a18d4e9",
                                    gestureHandling: "cooperative",
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                    disableDefaultUI: true
                                }}

                                mapContainerStyle={{ width: '100%', height: '50vh', borderRadius: '8px' }}>
                                <Marker
                                    position={{
                                        lat: center?.lat,
                                        lng: center?.lng
                                    }}
                                    options={{
                                        icon: UserLocation

                                    }}
                                />
                            </GoogleMap>
                        </Grid>
                    </Grid>

                </Container >
            </>
        </Scrollbar >
    );
}