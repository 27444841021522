import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box, Stepper, Step, StepLabel, Button, TextField, Autocomplete, List, ListItem, Chip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';

// auth
import { useAuthContext } from '../../auth/useAuthContext';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
type Loginrops = {
  id?: string;
  open: boolean;
  redirectPath?: string;
  // handleClickOpen: (id:string) => void;
  handleClose: () => void;
  handleOpenVenueDetails?: (id: string | undefined) => void;
};

type FormValuesProps = {
  name: string
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function Login(Props: Loginrops) {
  const { method, register, user } = useAuthContext();
  const { open, handleClose, id, redirectPath, handleOpenVenueDetails } = Props;

  const [signUp, setSignUp] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skip, setSkip] = useState(false);
  const [getaways, setGetaways] = useState([]);
  const [getaway, setGetaway] = useState<any>([]);
  const [homeCity, setHomeCity] = useState<any>(null);
  const [tags, setTags] = useState<any>([]);
  const [tag, setTag] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const setInitialData = async () => {
    try {
      const getawaysOptions = await axios.get("api/getaways");
      setGetaways(getawaysOptions.data.getaways);

      const tagOptions = await axios.post("api/user/profile/meta/free_time");

      let categoryTitle = "";
      let options = tagOptions.data.options[0].meta_info.map((option: any) => {
        if (option.title) categoryTitle = option.value;
        return {
          category: categoryTitle,
          ...option,
        }
      });

      options = options.filter((item: any) => !item.title);
      setTags(options);

    } catch (error) {
      console.error(error);
    }
  }


  const RegisterValidation = Yup.object().shape({
    // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    // password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    name: '',
    email: '',
    password: ''
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterValidation),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async ({ name, email, password }: FormValuesProps) => {
    try {
      setLoading(true);
      await register(name, email, password, '', '');
      setInitialData();
      setLoading(false);
      handleNext();
    } catch (error) {
      console.error(error);
      setError('afterSubmit', { type: "server", message: error?.errors?.message });
      setLoading(false);
    }
  };

  const updateGetaway = async () => {
    try {
      setLoading(true);
      if (homeCity?.id) await axios.patch(`api/getaways/store-current-getaway`, { getawayId: homeCity.id });
      setLoading(false);
      handleNext();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const updateTags = async () => {
    try {
      setLoading(true);
      handleNext();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const updateVisitedGataways = async () => {
    try {
      setLoading(true);
      if (getaway.length) await axios.post(`api/getaways/venue/visit`, { venue_id: getaway[0].id });
      setLoading(false);
      handleNext();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >

      <LoginLayout>
        {!signUp && <div style={{ fontFamily: 'Lato' }}>
          {/* style={{backgroundColor:'#192C36', width : '37.5rem', padding:'2.5rem', borderRadius:'0.938rem'}}  */}
          <Stack spacing={2} sx={{ mb: 2, position: 'relative' }} >
            <Typography variant="h5" style={{ fontWeight: 700 }}>Sign in to WKNDTRP</Typography>

            <Stack direction="row" spacing={0.5}>
              <Typography variant="subtitle1" style={{ color: '#919EAB', fontWeight: 400 }}>Enter your details below</Typography>

              {/* <Link variant="subtitle2">Create an account</Link> */}
            </Stack>

            {/* <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
          </Stack>

          {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
      </Alert> */}

          <AuthLoginForm
            // id={id}
            redirectPath={redirectPath}
            handleClose={handleClose}
            handleOpenVenueDetails={handleOpenVenueDetails}
          />
          {/* <AuthWithSocial /> */}
          <Stack direction="row" justifyContent="center" spacing={2} style={{ paddingTop: 10 }}>
            <Typography variant='subtitle2' onClick={() => { setSignUp(true) }} sx={{ cursor: "pointer" }}>No account? Create an account</Typography>
          </Stack>
        </div>}

        {signUp && <div style={{ fontFamily: 'Lato' }}>
          <Stack spacing={2} sx={{ mb: 2, position: 'relative' }} >
            <Typography variant="h5" style={{ fontWeight: 700 }}>Sign up to WKNDTRP</Typography>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step key={0}>
                <StepLabel>Register</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>Intro</StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>Home</StepLabel>
              </Step>
              <Step key={3}>
                <StepLabel>Tags</StepLabel>
              </Step>
              <Step key={4}>
                <StepLabel>Getaways</StepLabel>
              </Step>
              <Step key={5}>
                <StepLabel>Complete</StepLabel>
              </Step>
            </Stepper>
            {activeStep === 0 &&
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                  <Stack direction="row" spacing={0.5}>
                    <Typography variant="subtitle1" style={{ color: '#919EAB', fontWeight: 400 }}>Register Email Account</Typography>
                  </Stack>
                  <RHFTextField name="name" label="User Name" />
                  <RHFTextField name="email" label="Email" />
                  <RHFTextField
                    name="password"
                    label="Password"
                    type={false ? 'text' : 'password'}
                  />
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Register
                  </LoadingButton>
                  <Stack direction="row" justifyContent="center" style={{ marginTop: 10 }}>
                    <Typography variant='subtitle2' onClick={() => { setSignUp(false) }} sx={{ cursor: "pointer" }}>
                      Already have an account? Log in
                    </Typography>
                  </Stack>
                </Stack>
              </FormProvider>
            }
            {activeStep === 1 &&
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <Typography variant="subtitle1" style={{ color: '#A29060', fontWeight: 400, }}>
                    Hi {user?.username}
                  </Typography>
                  <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                    Welcome to WKNDTRP
                  </Typography>
                  <ol style={{ listStyleType: "disc", lineHeight: "200%" }}>
                    <li>
                      Enjoy the app for free for 2 weeks.
                    </li>
                    <li>
                      At the end of 2 weeks you will be able to subscribe to the app for $6.99 monthly, which you can cancel at any time.
                    </li>
                  </ol>
                </Stack>
                <LoadingButton
                  fullWidth
                  color="inherit"
                  size="large"
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    bgcolor: '#A29060',
                    color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                    '&:hover': {
                      bgcolor: 'text.primary',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                    },
                  }}
                >
                  Continue
                </LoadingButton>
              </Stack>
            }
            {activeStep === 2 &&
              <Stack spacing={3}>
                <Stack direction="row" spacing={0.5}>
                  <Typography variant="subtitle1" style={{ color: '#919EAB', fontWeight: 400 }}>Select your getaway</Typography>
                </Stack>
                <Autocomplete
                  value={homeCity}
                  options={getaways}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, selectedOptions) => setHomeCity(selectedOptions)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Home city"
                    />
                  )}
                />
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Back
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    variant="contained"
                    loading={loading}
                    onClick={updateGetaway}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Stack>
            }
            {activeStep === 3 &&
              <Stack spacing={3}>
                <Stack direction="row" spacing={0.5}>
                  <Typography variant="subtitle1" style={{ color: '#919EAB', fontWeight: 400 }}>Select tags about you</Typography>
                </Stack>
                <Autocomplete
                  multiple
                  value={tag}
                  options={tags}
                  groupBy={(option) => option.category}
                  getOptionLabel={(option: any) => option.value}
                  onChange={(e, selectedOptions) => setTag(selectedOptions)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tags"
                    />
                  )}
                />
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Back
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    variant="contained"
                    onClick={updateTags}
                    loading={loading}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Stack>
            }
            {activeStep === 4 &&
              <Stack spacing={3}>
                <Stack direction="row" spacing={0.5}>
                  <Typography variant="subtitle1" style={{ color: '#919EAB', fontWeight: 400 }}>Select getaways visited</Typography>
                </Stack>
                <Autocomplete
                  multiple
                  value={getaway}
                  options={getaways}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, selectedOptions) => setGetaway(selectedOptions)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Getaways"
                    />
                  )}
                />
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Back
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    variant="contained"
                    onClick={updateVisitedGataways}
                    loading={loading}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Stack>
            }
            {activeStep === 5 &&
              <Stack spacing={3}>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle1" style={{ color: '#A29060', fontWeight: 400, }}>
                    Hi {user?.username}
                  </Typography>
                  <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                    Welcome to WKNDTRP
                  </Typography>
                </Stack>
                {homeCity?.name &&
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle1" style={{ color: '#919EAB', fontWeight: 400 }}>
                      SELECTED HOME
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                      {homeCity?.name}
                    </Typography>
                  </Stack>
                }
                {!!tag.length &&
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle1" style={{ color: '#919EAB', fontWeight: 400 }}>
                      SELECTED TAGS
                    </Typography>
                    <Stack spacing={0.5} direction="row" useFlexGap flexWrap="wrap">
                      {tag.map((val: any) => (<Chip label={val.value} />))}
                    </Stack>
                  </Stack>
                }
                {!!getaway.length &&
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle1" style={{ color: '#919EAB', fontWeight: 400 }}>
                      SELECTED GETAWAYS
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                      {`Great! youve already been to ${getaway.length}/${getaways.length} getaways.`}
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                      {`Only ${getaways.length - getaway.length} to go.`}
                    </Typography>
                  </Stack>
                }
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    variant="contained"
                    onClick={handleBack}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Back
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    color="inherit"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => handleClose()}
                    sx={{
                      bgcolor: '#A29060',
                      color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : '#FFFF'),
                      '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                      },
                    }}
                  >
                    Explore WKNDTRP
                  </LoadingButton>
                </Stack>
              </Stack>
            }
          </Stack>
        </div>}
      </LoginLayout>
    </Dialog>
  );
}
