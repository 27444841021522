// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_EXPLORE = '/explore';
const ROOTS_CITY_GUIDES = '/cityguides';
const ROOTS_TRIPS = '/trips';
const ROOTS_OTHER = '/other';
const ROOTS_CONNECT = '/travellers';
const ROOTS_SUBSCRIPTION = '/subscribe';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/explore/view',
};

export const PATH_EXPLORE = {
  root:ROOTS_EXPLORE,
  view: path(ROOTS_EXPLORE,'/view'),
  detail: path(ROOTS_EXPLORE,'/detail/:id'),
};

export const PATH_CITY_GUIDES = {
  root:ROOTS_CITY_GUIDES,
  view: path(ROOTS_CITY_GUIDES,'/view'),
  map: path(ROOTS_CITY_GUIDES,'/cityMap'),

};

// export const PATH_CITY_MAP = {
//   root:ROOTS_CITY_MAP,
//   view: path(ROOTS_CITY_MAP,'/view'),
// };

export const PATH_TRIPS = {
  root:ROOTS_TRIPS,
  view: path(ROOTS_TRIPS,'/view'),
};

export const PATH_OTHER = {
  root:ROOTS_OTHER,
  view: path(ROOTS_OTHER,'/view'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  one: path(ROOTS_DASHBOARD, '/one'),
  two: path(ROOTS_DASHBOARD, '/two'),
  three: path(ROOTS_DASHBOARD, '/three'),
};

export const PATH_CONNECT ={
    root : ROOTS_CONNECT,
    view : path(ROOTS_CONNECT,'/view')
}

export const PATH_SUBSCRIPTION = {
  root: ROOTS_SUBSCRIPTION,
}

