import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/LoginPage';
import { useAuthContext } from './useAuthContext';
import Subscription from '../pages/Subscription/Subscription';
import axios from '../utils/axios'

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("");

  const checkStatus = async () => {
    try {
      const { data: { content } } = await axios.get('api/subscriptions/status');
      setStatus(content.status);
    } catch (e) {
      console.log(e);
    }
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  checkStatus();
  if (status === "expired") return <> <Subscription /> </>;
  return <> {children} </>;
}
