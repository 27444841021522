import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Dialog } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { ReactComponent as User } from '../../../images/user.svg'
import Login from '../../../sections/auth/Login';
import ProfileView from '../../../pages/Profile/ProfileView';
import axios from '../../../utils/axios'
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: '/',
  },
  {
    label: 'Settings',
    linkTo: '/',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [isOpenLogin, setOpenCloseLogin] = useState(false);
  const [isOpenProfile, setOpenCloseProfile] = useState<boolean>(false);
  const navigate = useNavigate();

  const { user, logout, isAuthenticated } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenProfile = () => {
    setOpenCloseProfile(true);
  };

  const handleCloseProfile = () => {
    setOpenCloseProfile(false);
    setOpenPopover(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClose = () => {
    setOpenCloseLogin(false)
    handleClosePopover()
  }

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  const stripeCustomerPortal = async () => {
    try {
      const { data: { content } } = await axios.get('api/subscriptions/stripe/customer-portal-link');
      window.location.href = content.url;
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {isAuthenticated ? <CustomAvatar src={user?.image_url} alt={user?.username} name={user?.username} /> : <User />}

      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
            {user?.first_name.toLowerCase()}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        {
          isAuthenticated &&
          <>
            <MenuItem onClick={handleOpenProfile} sx={{ m: 1 }}>
              Profile
            </MenuItem>
            <MenuItem onClick={stripeCustomerPortal} sx={{ m: 1 }}>
              Subscription
            </MenuItem>
            <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
              Logout
            </MenuItem>
          </>
        }

        {
          !isAuthenticated &&
          <MenuItem onClick={() => setOpenCloseLogin(true)} sx={{ m: 1 }}>
            Login
          </MenuItem>
        }

      </MenuPopover >

      <Login
        open={isOpenLogin}
        handleClose={handleClose}
      />

      <Dialog
        open={isOpenProfile}
        onClose={handleCloseProfile}
        slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(255, 255, 255, 0.2)' } } }}
        fullWidth
        maxWidth="lg"
      >
        <ProfileView
          handleClosePopUp={handleCloseProfile}
        />
      </Dialog>


    </>
  );
}
