import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  LoginPage,
  ExploreDashboard,
  VenueDetailsView,
  CityGuidesView,
  CityMap,
  TripDashboard,
  ConnectView,
  Subscription
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/explore',
      element: ( <DashboardLayout />),
      children: [
        { path: 'view', element: <ExploreDashboard /> },
        { path: 'view/:idParam', element: <ExploreDashboard /> },
        { path: 'details/:id', element: <AuthGuard><VenueDetailsView /></AuthGuard> },
    ]
    },
   
    {
      path: '/cityguides',
      element: (<DashboardLayout />),
      children: [
        { path: 'view', element: <CityGuidesView /> },
        { path: 'view/ambassador/:ambassadorId', element: <CityGuidesView /> },
        { path: 'view/venue/:venueIdParam', element: <CityGuidesView /> },
        { path: 'cityMap', element: <CityMap /> },
      ]
    },

    {
      path: '/trips',
      element: (<DashboardLayout />),
      children: [
        { path: 'view', element: <TripDashboard /> },
      ],
    },

    {
      path: '/travellers',
      element: (<DashboardLayout />),
      children: [
        { path: 'view', element: <AuthGuard><ConnectView /></AuthGuard> }
      ]
    },
    {
      path: '/subscribe',
      element: (<DashboardLayout />),
      children: [
        { path: '', element: <AuthGuard><Subscription /></AuthGuard> }
      ]
    },

    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}


 // {
    //   path: '/venueDetails',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: 'view', element: <VenueDetailsView /> },

    //   ],
    // },

    // {
    //   path: '/explore',
    //   element: (
    //     // <AuthGuard>
    //     <DashboardLayout />
    //     // </AuthGuard>
    //   ),
    //   children: [
    //     // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: 'view', element: <ExploreDashboard /> },
    //     { path: 'details/:id', element: <AuthGuard><VenueDetailsView /></AuthGuard> },
    //     // {
    //     //   path: 'user',
    //     //   children: [
    //     //     { element: <Navigate to="/dashboard/user/four" replace />, index: true },
    //     //     { path: 'four', element: <PageFour /> },
    //     //     { path: 'five', element: <PageFive /> },
    //     //     { path: 'six', element: <PageSix /> },
    //     //   ],
    //     // },
    //   ],
    // },
   
    // {
    //   path: '/cityguides',
    //   element: (<DashboardLayout />),
    //   children: [
    //     { path: 'view', element: <CityGuidesView /> },
    //     { path: 'cityMap', element: <CityMap /> },


    //   ],
    // },