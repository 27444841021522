import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          paddingBottom:10,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M96.6067 92.2252H92.9691V65.2073C92.9691 63.6826 92.0363 62.7511 90.513 62.7511L83.1444 62.751V48.0139C83.1444 46.4892 82.2116 45.5579 80.6883 45.5579H63.0857C61.5623 45.5579 60.2202 46.4893 60.2202 48.0141V92.2252H56.9453V7.07784C56.9453 5.88971 56.4338 4.9968 55.3078 4.6216C54.1818 4.24639 53.2398 4.77446 52.526 5.72534L32.7929 31.6396C32.436 32.1178 32.3835 32.6793 32.3835 33.277V92.2252H29.1086V34.1818C29.1086 32.6571 28.1758 31.6396 26.6525 31.6396H19.6933V16.0838C19.6933 14.5591 19.5792 13.6276 18.0559 13.6276C16.5325 13.6276 16.4184 14.559 16.4184 16.0837V31.6395L9.45926 31.6396C7.93588 31.6396 7.00309 32.571 7.00309 34.0957V92.2252H3.25974C1.31872 92.2252 0.5 92.3378 0.5 93.8625C0.5 95.3873 1.31872 95.5 3.25974 95.5L96.6067 95.4999C98.5477 95.4999 99.4722 95.3872 99.4722 93.8625C99.4722 92.3396 98.6535 92.2252 96.6067 92.2252ZM12.3248 92.2252V36.9278H23.3776V92.2252H12.3248ZM37.2959 92.2252L37.386 35.1014L51.974 15.6571L52.0329 92.2251L37.2959 92.2252Z" fill="white" />
          <path d="M63.4946 92.2249V48.8325H79.8691V66.0257H89.6938V92.2249H63.4946Z" fill="white" />
          <path d="M35.6582 92.2249V34.0954L53.6701 9.53369V92.2249H35.6582Z" fill="white" />
          <rect x="10.2778" y="34.9146" width="15.5558" height="57.3107" fill="white" />
        </svg>

      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
