import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));


export const ExploreDashboard = Loadable(lazy(() => import('../pages/Explore/ExploreDashboard')));
export const CityGuidesView = Loadable(lazy(() => import('../pages/CityGuides/CityGuidesView')));
export const VenueDetailsView = Loadable(lazy(() => import('../pages/Explore/VenueDetailsView')));
export const CityMap = Loadable(lazy(() => import('../pages/CityGuides/CityMap')));


export const TripDashboard = Loadable(lazy(() => import('../pages/Trips/TripDashboard')));

export const ConnectView = Loadable(lazy(() => import('../pages/Connect/ConnectView')))

export const Subscription = Loadable(lazy(() => import('../pages/Subscription/Subscription')))

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
